@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();


// 
/* For use in src/lib/core/theming/_palette.scss */
$mat-intradoviolet: (
    50 : #ebe6f0,
    100 : #cdc0da,
    200 : #ac96c1,
    300 : #8a6ca8,
    400 : #714d95,
    500 : #00677F,
    600 : #50287a,
    700 : #47226f,
    800 : #3d1c65,
    900 : #2d1152,
    A100 : #b88bff,
    A200 : #9958ff,
    A400 : #7a25ff,
    A700 : #6a0bff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #ffffff,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);

$mat-intradosky: (
    50 : #e0f6fc,
    100 : #b3e9f6,
    200 : #80daf1,
    300 : #4dcbeb,
    400 : #26c0e6,
    500 : #00b5e2,
    600 : #00aedf,
    700 : #00a5da,
    800 : #009dd6,
    900 : #008dcf,
    A100 : #f6fcff,
    A200 : #c3eaff,
    A400 : #90d7ff,
    A700 : #77ceff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);


// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$candy-app-primary: mat.m2-define-palette($mat-intradoviolet);
$candy-app-accent: mat.m2-define-palette($mat-intradosky);

// The warn palette is optional (defaults to red).
$candy-app-warn: mat.m2-define-palette(mat.$m2-red-palette);

// Create the theme object (a Sass map containing all of the palettes).
$candy-app-theme: mat.m2-define-light-theme($candy-app-primary, $candy-app-accent, $candy-app-warn);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
$my-primary: mat.m2-define-palette($mat-intradoviolet);
$my-accent: mat.m2-define-palette($mat-intradoviolet);
$my-warn: mat.m2-define-palette(mat.$m2-red-palette);

// create theme (use mat-dark-theme for themes with dark backgrounds)
$my-theme: mat.m2-define-light-theme(
                $my-primary,
                $my-accent,
                $my-warn
);
* {
  scrollbar-width: thin;
}
*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
*::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(132, 128, 128,.5);
}

.action-buttons button {
  margin-right: 16px;
}

.action-buttons button:last-child {
  margin-right: 0px;
}

@include mat.all-component-themes($my-theme);


table {
  width: 100%;
}

btn-link {
  color: #13294B;
  background-color: transparent;
  font-size: 17px;
  font-weight: 500;
}

.mdc-card--outlined {
  padding-left: 16px;
  padding-right: 16px;
}

.mat-mdc-card-title {
  align-items: baseline;
  font-size: 24px;
  font-weight: 500;
  height: 60px;
  padding: 16px 0px;
}

.mat-mdc-menu-item:hover:not([disabled]), .mat-menu-item.cdk-program-focused:not([disabled]), .mat-menu-item.cdk-keyboard-focused:not([disabled]), .mat-menu-item-highlighted:not([disabled]) {
  background-color: #00677F !important;
  color: white;
}

.mat-mdc-paginator {
  font-size: 12px;
}

.mdc-data-table__cell {
  font-size: 14px;
}

.mat-mdc-header-cell {
  color: rgba(0, 0, 0, 0.54);
  font-size: 12px;
  font-weight: 500;
}

.mat-mdc-form-field-focus-overlay {
  :hover {
    background-color: transparent !important;
  }
  background-color: white !important;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  :hover {
    background-color: transparent !important;
  }
  background-color: white !important;
}

.mat-mdc-tooltip {
  --mdc-plain-tooltip-supporting-text-size: 12px;
}

.mat-mdc-form-field-error {
  font-size: 14px !important;
}

.mat-mdc-form-field-error-wrapper {
  padding: 0px 5px !important;
}

.mat-mdc-form-field {
  padding: 0px 5px;
  margin-bottom: -5px;
}

.mdc-text-field {
  padding: 0px 5px !important;
}

.mat-mdc-paginator-page-size-select {

  .mdc-text-field {
      padding: 0px 16px !important;
    }

}

.btn-link:hover {
  color: #00677F;
  text-decoration: underline;
  background-color: transparent;
  border-color: transparent;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-default {
  cursor: default;
}

.btn:focus{
  box-shadow: none;
}

.btn-dark {
  color: #fff;
  background-color: #13294B;
  border-color: #13294B;
}

.btn-dark:focus {
  color: #fff;
  background-color: #13294B;
  border-color: #13294B;
}

.btn-dark:hover {
  color: #fff;
  background-color: #00677F;
  border-color: #00677F;
}

.btn-dark:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: #00677F;
  border-color: #00677F;
}

.mat-tab-body-content {
  height: 100%;
  overflow: hidden !important;
}

.mat-mdc-header-cell {
  font-style: bold;
}

.headerPosition {
  float: right;
  font-size: 12pt;
}

.fontSize {
  font-size: 18px;
}

.scrollbar {
  height: 400px;
  background: #fff;
  overflow-y: scroll;
}

.force-overflow {
  min-height: 450px;
}

.scrollbar-primary::-webkit-scrollbar {
  width: 6px;
  background-color: #F5F5F5;
}

.scrollbar-primary::-webkit-scrollbar-thumb {
  border-radius: 4px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #00677F;
}

/*
.form-fields-width {
  width: 55vh;
  font-size: 15px
}
 */

cdk-virtual-scroll-viewport {
  height: 100vh;

  li {
    height: 100px;
  }

  // Bonus points
  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  &::-webkit-scrollbar-thumb {
    background-color: #00677F;
  }
}

button:focus {
  outline: none;
  border: 0;
}

.scrollbar-pink::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #F5F5F5;
  border-radius: 10px;
}

.scrollbar-pink::-webkit-scrollbar {
  width: 8px;
  background-color: #F5F5F5;
}

.scrollbar-pink::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #007bff;
}

.thin::-webkit-scrollbar {
  width: 8px;
}

.bordered-pink::-webkit-scrollbar-track {
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 0px solid #ec407a;
}

.bordered-pink::-webkit-scrollbar-thumb {
  -webkit-box-shadow: none;
  box-shadow: none;
}

app-full-report h1 {
  font-size: 2.1em;
  font-weight: bold;
}

app-full-report h2 {
  font-size: 1.5em;
  font-weight: bold;
}

app-full-report h3 {
  font-size: 1.17em;
  font-weight: bold;
}

.visible-scrollbar, .invisible-scrollbar, .mostly-customized-scrollbar {
  display: block;
  // width: 10em;
  overflow: auto;
  // height: 2em;
}

.invisible-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Demonstrate a "mostly customized" scrollbar
 * (won't be visible otherwise if width/height is specified) */
.mostly-customized-scrollbar::-webkit-scrollbar {
  width: 5px;
  height: 8px;
  background-color: white; /* or add it to the track */
  border-radius: 15px;

}

/* Add a thumb */
.mostly-customized-scrollbar::-webkit-scrollbar-thumb {
  background: #00677F;
  border-radius: 15px;
}
       
code {
  font-size: 85%;
  color: #4b4b4b;
  word-break: break-word;
  background: #ececec;
  padding: 3px 5px;
}

.mat-mdc-snack-bar-container {
  max-width: 100% !important;
  float:left !important;
}

.mat-mdc-simple-snack-bar {
  display: flex !important;
  float:left !important;
}


.action-button {
  width: inherit;
  color: #00677F;
  font-size: 17px;
  font-weight: 500;
}

.mat-mdc-paginator-outer-container {
  display: block !important;
}

.dialog-content {
  padding: 24px;
  background: #f8f8f8;
}

.mat-mdc-dialog-content {
  padding: 0px !important;
}

.mat-mdc-dialog-container {
  padding: 0px !important;
  margin: 10px 0px 10px 0px !important;
  height: auto !important;
  max-height: 100% !important;
}
.action-buttons {
  margin-top: 15px;
  margin-bottom: -10px;
}

.mat-mdc-dialog-title {
  padding: 5px 15px;
  margin: -24px -24px 24px -24px !important;
  background-color: #13294B !important;
  text-align: center;
  color: white !important;
}

.nxt-dt-calendar-cell-selected {
  background-color: #00677f !important;
}

.nxt-dt-calendar-table {
  font-weight: 400 !important;

  .nxt-dt-calendar-header {
    .nxt-dt-weekdays {
      th {
        font-size: 0.8em !important;
        font-weight: 500 !important;
      }
    }
  } 
}

.nxt-dt-timer-content {
  .nxt-dt-timer-input {
    font-size: 0.9em !important;
  }
} 

.nxt-dt-timer-divider:before {
  top: 44% !important;
}
.nxt-dt-timer-divider:after {
  bottom: 44% !important;
}
.nxt-dt-timer-divider:before, .nxt-dt-timer-divider:after {
  width: 0.20em !important;
  height: 0.20em !important;
}

.nxt-dt-container-control-button {
  background-color: #00677f !important;
  color: white !important;
}

.nxt-dt-container-buttons {
  gap: 1px !important;
}


.mat-datepicker-content {
  .mat-calendar-period-button {
    font-weight: bold;
    color: #646464;
  }
  .mat-calendar-table-header {
    th {
    font-weight: bold;
    }
  }
  .mat-calendar-body-label {
    color: #868686;
    font-weight: bold;
  }

  .ngx-mat-timepicker{
    margin-top: 5px;

    form {
      .table {
        margin-left: 0px;
        margin-bottom: 0px;

        .tbody {
          td{
            padding: 0px;
            vertical-align: unset;
            border-top: none;
            
            .mat-mdc-icon-button {
              height: 25px;
              line-height: 20px;
            }

            .mat-mdc-form-field {
              width: 50px;
              max-width: 50px;

              .mat-form-field-infix {
                border-top: 0.4em solid transparent;
              }
            }
          }
        }
      }
    }
  } 

  .mat-datepicker-content {
    .time-container {
      padding-top: 0px;
    }
  }
}

.mat-autocomplete-panel .mat-option {
  height: 38px !important;
}

.mat-select-panel-wrap .mat-option {
  height: 24px !important
}